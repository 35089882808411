<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <!-- <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('contact/add')"
        /> -->
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Kontaktiere uns Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>

      <Column field="id" header="ID">
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
      </Column>
      <Column field="title" header="Title" />

      <Column field="status" header="Status" />

      <Column field="createdAt" header="Created At">
        <template #body="slotProps">
          {{ $durationFormatFullDate(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('supportTickets/show/' + slotProps.data.id)"
            icon="pi pi-eye"
            class="p-button-info p-mr-2 p-button-rounded"
          ></Button>
          <!-- <Button
                type="button"
                icon="pi pi-trash"
                v-if="slotProps.data.id != 1"
                @click="deleteItem(slotProps.data.id)"
                class="p-button-warning  ml-2  p-button-rounded"
              ></Button> -->
        </template>
      </Column>

   
      
    </DataTable>

    <Dialog
      header="Add Price"
      :visible.sync="displayProductsAdd"
      :style="{ width: '50vw' }"
    >
      <div class="p-m-0">
        <div class="mb-3">
          <label for="messageReply" class="form-label required">
            Message Reply
            <span>*</span>
          </label>
          <textarea class="form-control" v-model="d.messageReply" />
        </div>
      </div>
      <template #footer>
        <Button
          label="Hinzufügen"
          icon="pi pi-check"
          @click="addProducts()"
          autofocus
        />
        <Button
          label="Colose"
          icon="pi pi-times"
          @click="cDialog()"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],

      expandedRows: [],
      loading: true,
      filters: {},
      displayProductsAdd: false,
      d: {
        messageReply: null,
      },
    };
  },
  methods: {
    getData() {
      this.$http.get(`supportTickets`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    addProducts() {
      if (this.d.messageReply) {
        this.displayProductsAdd = false;
        this.$http.put(`supportTickets/${this.d.id}`, this.d).then(
          (response) => {
            this.d = {
              messageReply: null,
            };
            this.getData();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Data Added Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      }
    },
    openDialog(data) {
      this.displayProductsAdd = true;
      this.d = { ...data };
    },
    cDialog() {
      this.displayProductsAdd = false;
      this.d = {
        id: null,
        messageReply: null,
        name: null,
        subject: null,
      };
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`supportTickets/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
